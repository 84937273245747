import { useState } from "react";
import { NavLink } from "react-router-dom";

import Logo from "../../assets/images/general-mills.svg";
import DashboardIcon from "../../assets/images/Layer_1.png";
import DistributerIcon from "../../assets/images/distribution.png";
import BakersIcon from "../../assets/images/gloves.png";
import ProductIcon from "../../assets/images/product (1).png";
import RecipesIcon from "../../assets/images/cooking (1).png";
import ChefsIcon from "../../assets/images/chef (1).png";
import AsmIcon from "../../assets/images/briefcase (1).svg";
import OfficerIcon from "../../assets/images/officer (1).svg";
import FeedbackIcon from "../../assets/images/feedback.svg";
import NotificationIcon from "../../assets/images/notification.svg";
import CategoryIcon from "../../assets/images/categories.svg";
import UserIcon from "../../assets/images/07 contact.svg";
import FaqIcon from "../../assets/images/information.svg";

const SideBar = () => {
  const [expandedMenus, setExpandedMenus] = useState({});

  const allLinks = [
    {name: 'Dashboard', img: DashboardIcon, url: '/dashboard'},
    {name: 'Distributors', img: DistributerIcon, url: '/distributor'},
    {name: 'Bakeries', img: BakersIcon, url: '/baker'},
    {name: 'Products', img: ProductIcon, url: '/products'},
    {name: 'Products Category', img: CategoryIcon, url: '/product-categories'},
    {name: 'Recipes', img: RecipesIcon, url: '/recipes'},
    {name: 'Pillsbury Chefs', img: ChefsIcon, url: '/pillsbury-chef'},
    {name: 'ASM', img: AsmIcon, url: '/asm'},
    {name: 'City', img: AsmIcon, url: '/city'},
    {name: 'Sales Officer (SO)', img: OfficerIcon, url: '/so'},
    {name: 'Feedbacks', img: FeedbackIcon, url: '/feedback'},
    {name: 'Notification', img: NotificationIcon, url: '/notification'},
    {name: 'Bakers', img: UserIcon, url: '/user'},
    {name: 'FAQs', img: FaqIcon, url: '/faq'},
    {
      name: 'Inspiration Gallery',
      img: CategoryIcon,
      url: '',
      childrens: [
        {name: 'Design Category', img: CategoryIcon, url: '/design-category'},
        {name: 'Design Library', img: ProductIcon, url: '/design-library'},
        {name: 'Design Approval', img: ProductIcon, url: '/design-approval'},
      ]
    },
    // {
    //   name: 'Marketing Toolkit',
    //   img: CategoryIcon,
    //   url: '',
    //   childrens: [
    //     {name: 'Marketing Toolkit Designs Category', img: ProductIcon, url: '/marking-toolkit-design-category'},
    //     {name: 'Marketing Toolkit Designs', img: ProductIcon, url: '/marking-toolkit-designs'},
    //   ]
    // },
    // {name: 'SO-Report', img: OfficerIcon, url: '/so-report'},
  ];

  const showChildMenu = (index) => {
    setExpandedMenus((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <div className="sidebar left-margin-50 fixed">
      <div className="sidebar-logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="sidebar-menus grey-text">
        <ul className="ul">
          {allLinks.map((item, i) => (
            <li className={`top-margin-40`} key={i} onClick={() => showChildMenu(i)}>
              {item.url ? (
                <NavLink activeClassName="active" to={item.url}>
                  <img src={item.img} height={20} width={20} alt={item.name} /> {item.name}
                </NavLink>
              ) : (
                <span>
                  <img src={item.img} height={20} width={20} alt={item.name} /> {item.name}
                  {item.childrens?.length ? expandedMenus[i] ? <span className="up_arrow" /> : <span className="down_arrow" /> : ''}
                </span>
              )}
              {item.childrens?.length && expandedMenus[i] && (
                <ul>
                  {item.childrens.map((itm, ind) => (
                    <li key={ind}>
                      <NavLink activeClassName="active" to={itm.url}>
                        {itm.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
